/** @format */

import React, {useEffect} from 'react';

import styles from '@styles/common/Confirmation.module.scss';
import CloseImg from '@imageWrapper/closeImg';

const wrapperRef = React.createRef();
function ConfirmationModal(props) {
	const {
		eventButton,
		headingMessage,
		message,
		buttonStart,
		buttonEnd,
		action,
		modalType,
		buttonShow,
	} = props;

	useEffect(() => {
		const removeListener = () => {
			document.removeEventListener('mousedown', outSideModalClickEvent);
		};
		const outSideModalClickEvent = () => {
			if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
				removeListener();
				eventButton(false, action);
			}
		};
		document.addEventListener('mousedown', outSideModalClickEvent);
	}, [action, eventButton]);

	const handleIcon = modalType => {
		const icons = {
			error: `fa fa-times-circle ${styles.error}`,
			warning: `fas fa-exclamation-triangle ${styles.warning}`,
			success: `fas fa-check-circle ${styles.success}`,
		};
		return icons[modalType];
	};

	return (
		<div
			className={`modal fade custom-popup xs-popup show ${styles.confirmationPopup}`}
			id='confirmationModal'
			role='dialog'
		>
			<div className='modal-dialog'>
				<div className='modal-content  pd_10' ref={wrapperRef}>
					<CloseImg click={() => eventButton(false, action)} />

					<div className={`${styles.confirmationBody}`}>
						<div className={styles.icon}>
							<i className={handleIcon(modalType)}></i>
						</div>
						<h5>{headingMessage} </h5>
						<p> {message} </p>
						<div className={`${styles.formGroup} form-group`}>
							{buttonShow ? (
								<>
									<button
										className={styles.btnCustom}
										onClick={() => eventButton(true, action)}
									>
										{buttonStart}
									</button>

									<button
										className={styles.btnCustom}
										onClick={() => eventButton(false, action)}
									>
										{buttonEnd}
									</button>
								</>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ConfirmationModal;
