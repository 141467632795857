/* eslint-disable @next/next/no-img-element */
import {useState} from 'react';
import Link from 'next/link';
import Image from 'next/image';

import {useApp} from '@context/AppProvider';
import {useGoodCause} from '@context/GoodCauseProvider';
import {useCart} from '@context/CartProvider';
import ConfirmationModal from '@models/confirmation.modal';

import Search from './Search';
function TopMiddle(props) {
	const {
		styles,
		showHideSearch,
		showHideMobileMenu,
		showModel,
		// hideMenu,
		hideGoodCause,
		hideSearch,
	} = props;
	const {
		logout,
		idb: {user},
	} = useApp();
	const {gc} = useGoodCause();
	const {cartCount, updateCartCount, wishlistCount, updateWishlistCount} =
		useCart();
	const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

	const onLogout = () => {
		updateCartCount(0);
		updateWishlistCount(0);
		logout();
		setShowLogoutConfirm(true);
		let timeout = setTimeout(() => {
			setShowLogoutConfirm(false);
			clearTimeout(timeout);
		}, 5000);
	};

	const signOutEventButton = () => {
		setShowLogoutConfirm(false);
	};

	const menuLists = user ? (
		<li>
			<span className={styles.userNameClass}>
				MY ACCOUNT
				<div className={styles.img_arrow}>
					<Image
						layout='fill'
						src='/assets/images/sign-arrow.svg'
						alt='sign-arrow'
					/>
				</div>
			</span>

			<ul className={styles.openSubNav}>
				<li>
					<Link href='/my-account'>
						<a>MY ACCOUNT</a>
					</Link>
				</li>
				<li>
					<Link href='/wishlist'>
						<a>Wishlist</a>
					</Link>
				</li>
				<li>
					<Link href='/give'>
						<a>Nominate A Cause</a>
					</Link>
				</li>
				<li>
					<span className='link_type_2' onClick={() => onLogout()}>
						SIGN OUT
					</span>
				</li>
			</ul>
		</li>
	) : (
		<li>
			<span className={styles.userNameClass}>
				SIGN IN / JOIN
				<div className={styles.img_arrow}>
					<Image
						layout='fill'
						src='/assets/images/sign-arrow.svg'
						alt='sign-arrow'
					/>
				</div>
			</span>
			<ul className={styles.openSubNav}>
				<li onClick={() => showModel('login')}>
					<span className='link_type_2'>SIGN IN</span>
				</li>
				<li onClick={() => showModel('register')}>
					<span className='link_type_2'>JOIN</span>
				</li>
			</ul>
		</li>
	);
	return (
		<div
			className={`${styles.headerMedial} d-flex align-items-center justify-content-between`}
		>
			<div className={styles.leftHdBlock}>
				<span className='menuImage' onClick={() => showHideMobileMenu(true)}>
					<div className='container2'>
						<div className='bar1'></div>
						<div className='bar2'></div>
						<div className='bar3'></div>
					</div>
				</span>
			</div>
			<div className={`${styles.logo}`}>
				<Link href='/'>
					<a>
						{/* <Image alt='' src='/img/main-logo.png' width='310' height='45' /> */}
						<img
							className={styles.logoImage}
							src='/img/main-logo.png'
							alt='logo'
						/>
					</a>
				</Link>
			</div>

			{/* search Component Start */}
			{!hideSearch && <Search styles={styles} />}

			{/* search Component End */}

			<div className={`${styles.headerRightBlock}`}>
				<ul className={`d-flex align-items-center`}>
					{user && !hideGoodCause && (
						<li className={styles.selectGoodCause}>
							<span>MY GOOD CAUSE</span>
							<div className={styles.selectCharity}>
								<span className={styles.charityName}>
									{gc.name ? gc.name.substring(0, 11) + '...' : ''}
								</span>
								<Link href='/my-good-cause'>
									<a>{gc.name ? 'Change' : 'Select a good cause.'}</a>
								</Link>
							</div>
						</li>
					)}
					{menuLists}
					<li className={`${styles.searchElement}`}>
						<span onClick={showHideSearch}>
							<i className={`fas fa-search`}></i>
						</span>
					</li>
					<li className={`${styles.bagIcons}`}>
						<Link href='/wishlist'>
							<a>
								<img src='/img/heart-icon-res.png' alt='logo' />
								{wishlistCount > 0 && (
									<span
										className={`${styles.shopingCart} ${styles.shopingCartOption}`}
									>
										{wishlistCount <= 9 ? wishlistCount : '9+'}
									</span>
								)}
							</a>
						</Link>
					</li>
					<li className={`${styles.bagIcons}`}>
						<Link href='/checkout'>
							<a>
								<img src='/img/cart-icon-res.png' alt='logo' />
								{cartCount > 0 && (
									<span className={`${styles.shopingCart}`}>
										{cartCount <= 9 ? cartCount : '9+'}
									</span>
								)}
							</a>
						</Link>
					</li>
				</ul>
			</div>
			{showLogoutConfirm && (
				<ConfirmationModal
					eventButton={signOutEventButton}
					headingMessage={''}
					message={'You have signed out successfully.'}
					buttonShow={false}
					action={'none'}
					modalType={'success'} //"error","warning","success"
				></ConfirmationModal>
			)}
		</div>
	);
}

export default TopMiddle;
