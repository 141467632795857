/** @format */

function AdvanceSearch(props) {
	const {styles, advanceSearchData, setAdvanceSearchData} = props;

	const handleOnChange = e => {
		setAdvanceSearchData(preState => ({
			...preState,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<div className={styles.advanceSearchBlock}>
			<div className='row'>
				<div className='col-md-4'>
					<div className={styles.formGroup}>
						<select
							className='form-control'
							name='refineBy'
							value={advanceSearchData.refineBy}
							onChange={e => handleOnChange(e)}
						>
							<option value>Refine by</option>
							<option value='product_id'>Product ID</option>
							<option value='sku_code'>SKU</option>
							<option value='gtin_code'>GTIN</option>
							<option value='upc_code'>UPC Code</option>
							<option value='ean_code'>EAN Code</option>
							<option value='barcode'>Barcode</option>
							<option value='gs1_code'>GS1 Code</option>
						</select>
					</div>
				</div>
				<div className='col-md-4'>
					<div className={styles.formGroup}>
						<input
							type='text'
							name='refineKey'
							value={advanceSearchData.refineKey}
							onChange={e => handleOnChange(e)}
							placeholder='Enter here'
							className='form-control'
						/>
					</div>
				</div>
				<div className='col-md-4'>
					<div className={styles.formGroup}>
						<select
							className='form-control'
							name='distance'
							value={advanceSearchData.distance}
							onChange={e => handleOnChange(e)}
						>
							<option value='20000'>All</option>
							<option value='2.5'>1km</option>
							<option value='8'>5km</option>
							<option value='14'>10km</option>
							<option value='19'>15km</option>
							<option value='35'>30km</option>
							<option value='55'>50km</option>
							<option value='110'>100km</option>
							<option value='260'>250km</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdvanceSearch;
