/* eslint-disable @next/next/no-img-element */
/** @format */
import {useState, useRef, useEffect, useCallback} from 'react';
import {useRouter} from 'next/router';
import Link from 'next/link';
import axios from 'axios';

import Loading from '@components/Loading';
import {api} from '@utils/api';
import {useApp} from '@context/AppProvider';

const Search = props => {
	const router = useRouter();
	const wrapperRef = useRef(null);
	const {
		idb: {session, user},
	} = useApp();
	const {styles} = props;
	const req = useRef(null);

	const advanceSearchData = {
		refineBy: '',
		refineKey: '',
		distance: '',
	};
	const [data, setData] = useState({
		isLoader: false,
		searchVal: '',
		products: [],
		brands: [],
		categories: [],
		showSearch: false,
	});

	const searchData = value => {
		let rc = 1;
		let location =
			origin && origin === 'au' ? 'Australia' : 'International' || 'Australia';
		let url = '/products?name=' + encodeURIComponent(value);
		url += '&location=' + location;
		url += '&latitude=';
		url +=
			user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.lat
				: '';
		url += '&longitude=';
		url +=
			user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.long
				: '';
		url += '&radius=' + advanceSearchData.distance || '';
		url +=
			'&suburb=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.suburb
				: '';
		url +=
			'&state=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.state
				: '';
		url +=
			'&postcode=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.postcode
				: '';
		url += '&rc=' + rc;
		if (advanceSearchData.refineBy) {
			url += '&refineBy=' + advanceSearchData.refineBy;
			url += '&refineKey=' + advanceSearchData.refineKey;
		}
		router.push(url);
		resetBlock();
	};
	const quickSearch = async value => {
		try {
			if (value) {
				if (req.current) {
					req.current.cancel();
				}
				req.current = axios.CancelToken.source();
				setData(preState => ({
					...preState,
					searchVal: value,
					isLoader: true,
				}));
				const {cancelled, products, brands, categories} = await api(
					{
						url: `/product/quicksearch`,
						method: 'POST',
						cancelToken: req.current.token,
						data: {
							q: value,
						},
					},
					session,
				);
				if (cancelled) {
					return false;
				}
				setData(preState => ({
					...preState,
					isLoader: false,
					products: products,
					brands: brands,
					categories: categories,
					showSearch: true,
				}));
			} else resetBlock();
		} catch (e) {
			resetBlock();
		}
	};

	const transformContent = content => {
		let temp = content;
		let q = data.searchVal.trim();
		var index = temp.toLowerCase().indexOf(q.toLowerCase());
		if (index >= 0) {
			temp =
				temp.substring(0, index) +
				'<strong>' +
				temp.substring(index, index + q.length) +
				'</strong>' +
				temp.substring(index + q.length);
			return temp;
		} else {
			return temp;
		}
	};

	const resetBlock = () => {
		setData(preState => ({
			...preState,
			searchVal: '',
			showSearch: false,
			products: [],
			brands: [],
			categories: [],
			isLoader: false,
		}));
	};

	const handleClickOutside = useCallback(
		event => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				const id =
					event && event.path && event.path.length > 0 && event.path[0].id
						? event.path[0].id
						: '';
				if (id !== 'click-search-options')
					setData(preState => ({
						...preState,
						searchVal: '',
						showSearch: false,
					}));
			}
		},
		[setData],
	);

	useEffect(() => {
		resetBlock();
	}, [router]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, [handleClickOutside]);

	return (
		<div ref={wrapperRef} className={`${styles.searchHeaderBlock}`}>
			{data.isLoader ? (
				<div className={styles.loaderPosition}>
					{' '}
					<Loading.LoaderThree />{' '}
				</div>
			) : (
				<button
					className={`${styles.searchElementBtn}`}
					onClick={() => {
						if (data.searchVal) searchData(data.searchVal);
					}}
				>
					<img src='/img/search-icon.png' alt='logo' />
				</button>
			)}
			<input
				type='text'
				className={`${styles.searchInpt}`}
				placeholder='Search brands, categories or products'
				value={data.searchVal}
				onKeyPress={e => {
					if (e.key.toLowerCase() === 'enter' && data.searchVal)
						searchData(data.searchVal);
				}}
				onChange={e => quickSearch(e.target.value)}
			/>
			{(data.brands.length > 0 ||
				data.categories.length > 0 ||
				data.products.length > 0) &&
				data.showSearch && (
					<div className={styles.fillterSearchOpen}>
						<div className={styles.searchBlock}>
							{data.brands.length > 0 && (
								<div className={styles.listSearchCtg}>
									<h4>BRANDS</h4>
									<ul>
										{data.brands.map(item => {
											return (
												<li key={item._id}>
													<Link href={`/brand/${item.slug}`}>
														<a
															onClick={() => resetBlock('link')}
															dangerouslySetInnerHTML={{
																__html: transformContent(item.Name),
															}}
														></a>
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							)}
							{data.categories.length > 0 && (
								<div className={styles.listSearchCtg}>
									<h4>CATEGORIES</h4>
									<ul>
										{data.categories &&
											data.categories.map(item => {
												let name = item.Name,
													link = `/products/${item.slug}`;
												if (item?.parents?.length > 0) {
													name += ' in ';
													item.parents.map((child, index) => {
														name += `${child.Name}${
															index < item.parents.length - 1 ? ' - ' : ''
														}`;
													});
												}

												if (item?.parents?.length > 2) {
													link = `/products/${
														item.parents[item.parents.length - 1].slug
													}?category=${item.slug}`;
												}
												return (
													<li key={item._id}>
														<Link href={link}>
															<a
																onClick={() => resetBlock('link')}
																dangerouslySetInnerHTML={{
																	__html: transformContent(name),
																}}
															></a>
														</Link>
													</li>
												);
											})}
									</ul>
								</div>
							)}
							{data.products.length > 0 && (
								<div className={styles.listSearchCtg}>
									<h4>PRODUCTS</h4>
									<ul>
										{data.products.map(item => {
											return (
												<li key={item._id}>
													<Link href={`/product/${item.slug}`}>
														<a
															onClick={() => resetBlock('link')}
															dangerouslySetInnerHTML={{
																__html: transformContent(item.product_name),
															}}
														></a>
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</div>
						<button
							className={styles.btnCustom}
							onClick={() => searchData(data.searchVal)}
						>
							Show all results
						</button>
					</div>
				)}
		</div>
	);
};

export default Search;
