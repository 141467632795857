import Image from 'next/image';

import styles from '@styles/imageWrapper/images.module.scss';

function CloseImg(props) {
	const {click} = props;
	return (
		<button type='button' className='close' onClick={click}>
			<div className={styles.closeImg}>
				<Image
					layout='fill'
					src='/assets/images/close-icon.svg'
					alt='close-icon'
				/>
			</div>
		</button>
	);
}
export default CloseImg;
