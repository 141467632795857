/* eslint-disable @next/next/no-img-element */
/** @format */
import {useState, useRef} from 'react';
import Link from 'next/link';
import axios from 'axios';

import Loading from '@components/Loading';
import {api} from '@utils/api';
import {useApp} from '@context/AppProvider';
function BasicSearch(props) {
	const {
		idb: {session},
	} = useApp();
	const req = useRef(null);
	const {styles, searchData, setAdvanceSearchOpen, setShowSearch} = props;
	const [data, setData] = useState({
		isLoader: false,
		searchVal: '',
		products: [],
		brands: [],
		categories: [],
		showSearch: false,
	});

	const quickSearch = async value => {
		try {
			if (value) {
				if (req.current) {
					req.current.cancel();
				}
				req.current = axios.CancelToken.source();
				setData(preState => ({
					...preState,
					searchVal: value,
					isLoader: true,
				}));
				const {cancelled, products, brands, categories} = await api(
					{
						url: `/product/quicksearch`,
						method: 'POST',
						cancelToken: req.current.token,
						data: {
							q: value,
						},
					},
					session,
				);
				if (cancelled) {
					return false;
				}
				setData(preState => ({
					...preState,
					isLoader: false,
					products: products,
					brands: brands,
					categories: categories,
					showSearch: true,
				}));
			} else resetBlock('reset');
		} catch (e) {
			resetBlock('reset');
		}
	};

	const transformContent = content => {
		let temp = content;
		let q = data.searchVal.trim();
		var index = temp.toLowerCase().indexOf(q.toLowerCase());
		if (index >= 0) {
			temp =
				temp.substring(0, index) +
				'<strong>' +
				temp.substring(index, index + q.length) +
				'</strong>' +
				temp.substring(index + q.length);
			return temp;
		} else {
			return temp;
		}
	};

	const resetBlock = type => {
		setData(preState => ({
			...preState,
			searchVal: '',
			showSearch: false,
			products: [],
			brands: [],
			categories: [],
			isLoader: false,
		}));
		setAdvanceSearchOpen(false);
		if (type === 'link') setShowSearch(false);
	};
	return (
		<>
			<div className={styles.categorieSearch}>
				<div className={styles.categorySearchInner}>
					{data.isLoader ? (
						<div className={styles.loaderPosition}>
							{' '}
							<Loading.LoaderThree />{' '}
						</div>
					) : (
						<button
							className={`${styles.searchElementBtn}`}
							onClick={() => {
								if (data.searchVal) searchData(data.searchVal);
							}}
						>
							<img src='/img/search-icon.png' alt='logo' />
						</button>
					)}
					{/* desktop Input start */}
					<input
						type='text'
						placeholder='Search for products, brands, categories.'
						className={`form-control ${styles.desktop}`}
						value={data.searchVal}
						onKeyPress={e => {
							if (e.key.toLowerCase() === 'enter' && data.searchVal)
								searchData(data.searchVal);
						}}
						onChange={e => quickSearch(e.target.value)}
					/>
					{/* desktop Input end */}
					{/* mobiles Input start */}
					<input
						type='text'
						placeholder='products, brands, categories.'
						className={`form-control ${styles.mobile}`}
						value={data.searchVal}
						onKeyPress={e => {
							if (e.key.toLowerCase() === 'enter' && data.searchVal)
								searchData(data.searchVal);
						}}
						onChange={e => quickSearch(e.target.value)}
					/>
					{/* mobiles Input end */}
					{/* <button
						className={`btnCustom ${styles.btnCustom}`}
						disabled={data.isLoader || !data.searchVal}
						onClick={() => searchData(data.searchVal)}
					>
						{data.isLoader ? (
							<div className={styles.loaderPosition}>
								{' '}
								<Loading.LoaderThree />{' '}
							</div>
						) : (
							<i className='fas fa-search'></i>
						)}{' '}
						Search
					</button> */}
				</div>
				{(data.brands.length > 0 ||
					data.categories.length > 0 ||
					data.products.length > 0) &&
					data.showSearch && (
						<div className={styles.fillterSearchOpen}>
							<div className={styles.searchBlock}>
								{data.brands.length > 0 && (
									<div className={styles.listSearchCtg}>
										<h4>BRANDS</h4>
										<ul>
											{data.brands.map(item => {
												return (
													<li key={item._id}>
														<Link href={`/brand/${item.slug}`}>
															<a
																onClick={() => resetBlock('link')}
																dangerouslySetInnerHTML={{
																	__html: transformContent(item.Name),
																}}
															></a>
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{data.categories.length > 0 && (
									<div className={styles.listSearchCtg}>
										<h4>CATEGORIES</h4>
										<ul>
											{data.categories &&
												data.categories.map(item => {
													let name = item.Name,
														link = `/products/${item.slug}`;
													if (item?.parents?.length > 0) {
														name += ' in ';
														item.parents.map((child, index) => {
															name += `${child.Name}${
																index < item.parents.length - 1 ? ' - ' : ''
															}`;
														});
													}

													if (item?.parents?.length > 2) {
														link = `/products/${
															item.parents[item.parents.length - 1].slug
														}?category=${item.slug}`;
													}
													return (
														<li key={item._id}>
															<Link href={link}>
																<a
																	onClick={() => resetBlock('link')}
																	dangerouslySetInnerHTML={{
																		__html: transformContent(name),
																	}}
																></a>
															</Link>
														</li>
													);
												})}
										</ul>
									</div>
								)}
								{data.products.length > 0 && (
									<div className={styles.listSearchCtg}>
										<h4>PRODUCTS</h4>
										<ul>
											{data.products.map(item => {
												return (
													<li key={item._id}>
														<Link href={`/product/${item.slug}`}>
															<a
																onClick={() => resetBlock('link')}
																dangerouslySetInnerHTML={{
																	__html: transformContent(item.product_name),
																}}
															></a>
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								)}
							</div>
							<button
								className={styles.btnCustom}
								onClick={() => searchData(data.searchVal)}
							>
								Show all results
							</button>
						</div>
					)}
			</div>
		</>
	);
}

export default BasicSearch;
