/** @format */

import {useState} from 'react';

import SearchComponent from './searchProduct/SearchProducts.component';
import TopMiddle from './subMiddle/topMiddle.component';

function MiddleHeaderComponent(props) {
	const {
		styles,
		showHideMobileMenu,
		showModel,
		advanceSearchOpen,
		setAdvanceSearchOpen,
		hideSearch,
	} = props;

	const [showSearch, setShowSearch] = useState(false);

	// event to show hide search block
	const showHideSearch = () => {
		setShowSearch(!showSearch);
		setAdvanceSearchOpen(false);
	};
	return (
		<>
			{/* Middle part of top start */}
			<TopMiddle
				styles={styles}
				showHideMobileMenu={showHideMobileMenu}
				showHideSearch={showHideSearch}
				showModel={showModel}
				hideMenu={props.hideMenu}
				hideSearch={hideSearch}
				hideGoodCause={props.hideGoodCause}
			/>

			{/* Middle part of top start */}

			{/* Search product of top start */}
			{showSearch && (
				<SearchComponent
					styles={styles}
					advanceSearchOpen={advanceSearchOpen}
					setShowSearch={setShowSearch}
					setAdvanceSearchOpen={setAdvanceSearchOpen}
				/>
			)}
			{/* Search product of top start */}
		</>
	);
}

export default MiddleHeaderComponent;
