/** @format */

import React, {useState, useRef, useEffect, useCallback} from 'react';
import {useRouter} from 'next/router';

import {useApp} from '@context/AppProvider';

import AdvanceSearch from './AdvanceSearch.component';
import BasicSearch from './BasicSearch.component';

function SearchComponent(props) {
	const wrapperRef = useRef(null);
	const router = useRouter();
	const {
		idb: {origin, user},
	} = useApp();
	const {setAdvanceSearchOpen, advanceSearchOpen, setShowSearch} = props;
	const [advanceSearchData, setAdvanceSearchData] = useState({
		refineBy: '',
		refineKey: '',
		distance: '',
	});
	// event to show hide advance search block
	// const showHideAdvanceSearch = () => {
	// 	setAdvanceSearchOpen(!advanceSearchOpen);
	// };

	const searchData = value => {
		let rc = 1;
		let location =
			origin && origin === 'au' ? 'Australia' : 'International' || 'Australia';
		let url = '/products?name=' + encodeURIComponent(value);
		url += '&location=' + location;
		url += '&latitude=';
		url +=
			user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.lat
				: '';
		url += '&longitude=';
		url +=
			user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.long
				: '';
		url += '&radius=' + advanceSearchData.distance || '';
		url +=
			'&suburb=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.suburb
				: '';
		url +=
			'&state=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.state
				: '';
		url +=
			'&postcode=' + user?.user_location_preference.length > 0
				? user.user_location_preference[0]?.postcode
				: '';
		url += '&rc=' + rc;
		if (advanceSearchData.refineBy) {
			url += '&refineBy=' + advanceSearchData.refineBy;
			url += '&refineKey=' + advanceSearchData.refineKey;
		}
		router.push(url);
		setShowSearch(false);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, [handleClickOutside]);

	const handleClickOutside = useCallback(
		event => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				const id =
					event && event.path && event.path.length > 0 && event.path[0].id
						? event.path[0].id
						: '';
				if (id !== 'click-search-options') setShowSearch(false);
			}
		},
		[setShowSearch],
	);

	const {styles} = props;
	return (
		<div ref={wrapperRef} className={styles.headSearchBlock}>
			<div className={`container ${styles.container}`}>
				{/* Basic search options start */}
				<BasicSearch
					styles={styles}
					searchData={searchData}
					setShowSearch={setShowSearch}
					setAdvanceSearchOpen={setAdvanceSearchOpen}
				>
					{' '}
				</BasicSearch>
				{/* Basic search options end */}

				{/* event to view advance  search start */}
				{/* <div className={`${styles.advanceSearchHd}`}>
					<span
						className={styles.clickAdvanceSearch}
						onClick={showHideAdvanceSearch}
					>
						Advanced Search
						{!advanceSearchOpen ? (
							<i className='fas fa-angle-down'></i>
						) : (
							<i className='fas fa-angle-up'></i>
						)}
					</span>
				</div> */}
				{/* event to view advance  search end */}

				{/* Advance search options start */}
				{advanceSearchOpen && (
					<AdvanceSearch
						styles={styles}
						advanceSearchData={advanceSearchData}
						setAdvanceSearchData={setAdvanceSearchData}
					></AdvanceSearch>
				)}
				{/* Advance search options end */}
			</div>
		</div>
	);
}

export default SearchComponent;
